<template>
  <div class="main" :style="{ height: mainHeight }"            v-loading="loading"
       element-loading-text="loading"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="header">
      <el-carousel
          trigger="click"
          arrow="never"
          :autoplay="true"
          width="100%"
          ref="slideCarousel"
          indicator-position="outside"
      >
        <el-carousel-item @click="clickImg()">
          <img src="../assets/banner_01.jpg" />
        </el-carousel-item>
        <el-carousel-item @click="clickImg()">
          <img src="../assets/banner_02.jpg" />
        </el-carousel-item>
        <el-carousel-item @click="clickImg()">
          <img src="../assets/banner_03.jpg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="pannelList">
      <!-- 考虑修改一下，加个选中的状态，差分成v-for样式的 -->
      <div class="pannelList_item" @click="clickRecharge()">
        <img src="../assets/rec.png" />
        <span>Recharge</span>
      </div>
      <div class="pannelList_item" @click="viewWithdrawal()">
        <img src="../assets/withdrawal.png" />
        <span>Withdraw</span>
      </div>
      <div class="pannelList_item" @click="viewDetail()">
        <img src="../assets/detail.png" />
        <span>Details</span>
      </div>
      <div class="pannelList_item" @click="backApp()">
        <img src="../assets/back.png" />
        <span>Return</span>
      </div>
    </div>
    <div class="center" :style="{ height: centerHeight }">
      <div class="center_nickName">
        <span class="nickLeft">Nickname : {{ nickName }}</span>
        <input
            type="text"
            class="balanceRight"
            :disabled="true"
            v-model="balanceAmount"
        />
      </div>
      <div class="center_rechareAmount"
>
        <div
            class="rechareAmount_item"
            @click="activeAmount(amountList[0])"
            :class="{ rechareAmount_itemActive: rechareAmount == this.amountList[0] }"
        >
          <p>₹ {{ amountList[0] }}</p>
          <span v-for="item in configList" :key="item.id" v-show="amountList[0]>=(parseInt(item.amount_min))&&amountList[0]<(parseInt(item.amount_max))"> +{{item.total_rebate}}</span>
        </div>
        <div
            class="rechareAmount_item"
            @click="activeAmount(amountList[1])"
            :class="{ rechareAmount_itemActive: rechareAmount == this.amountList[1] }"
        >
          <p>₹ {{ amountList[1] }}</p>
          <span v-for="item in configList" :key="item.id" v-show="amountList[1]>=(parseInt(item.amount_min))&&amountList[1]<(parseInt(item.amount_max))">+{{item.total_rebate}}</span>
        </div>
        <div
            class="rechareAmount_item"
            @click="activeAmount(amountList[2])"
            v-if="this.configList"
            :class="{ rechareAmount_itemActive: rechareAmount == this.amountList[2] }"
        >
          <p>₹ {{ amountList[2] }}</p>
          <span v-for="item in configList" :key="item.id" v-show="amountList[2]>=(parseInt(item.amount_min))&&amountList[2]<(parseInt(item.amount_max))">+{{item.total_rebate}}</span>
        </div>
        <div
            class="rechareAmount_item"
            @click="activeAmount(amountList[3])"
            :class="{ rechareAmount_itemActive: rechareAmount == this.amountList[3] }"
        >
          <p>₹ {{ amountList[3] }}</p>
          <span v-for="item in configList" :key="item.id" v-show="amountList[3]>=(parseInt(item.amount_min))&&amountList[3]<(parseInt(item.amount_max))">+{{item.total_rebate}}</span>
        </div>
        <div
            class="rechareAmount_item"
            @click="activeAmount(amountList[4])"
            :class="{ rechareAmount_itemActive: rechareAmount == this.amountList[4] }"
        >
          <p>₹ {{ amountList[4] }}</p>
          <span v-for="item in configList" :key="item.id" v-show="amountList[4]>=(parseInt(item.amount_min))&&amountList[4]<(parseInt(item.amount_max))">+{{item.total_rebate}}</span>
        </div>
        <div
            class="rechareAmount_item"
            @click="activeAmount(amountList[5])"
            :class="{ rechareAmount_itemActive: rechareAmount == this.amountList[5] }"
        >
          <p>₹ {{ amountList[5] }}</p>
          <span v-for="item in configList" :key="item.id" v-show="amountList[5]>=(parseInt(item.amount_min))&&amountList[5]<(parseInt(item.amount_max))">+{{item.total_rebate}}</span>
        </div>
      </div>
      <div class="reminder">
        <span>Cash</span>
        <span class="reminder_bonus">Bonus</span>

      </div>
      <div class="center_custom">
        <input
            type="text"
            placeholder="Customized Amount"
            v-model="rechareAmount"
            @change="activeAmount(rechareAmount)"
            oninput="value=value.replace(/[^\d]/g,'')"
        />
        <span>+</span>
        <p class="rebate">{{rate}}</p>
        <span>+</span>
        <p class="rebate">{{bonusRate}}</p>


        <span>=</span>
        <input
            disabled
            type="text"
            placeholder="To get"
            class="amountTotal"
            v-model="totalAmount"
            oninput="value=value.replace(/[^\d]/g,'')"
        />
      </div>
      <div
          class="center_bottom"
          :style="{ marginTop: topHeight }"
          @click="confirm()"
      >
        <button>Get Chips</button>
      </div>
    </div>
    <div class="dialog">
      <el-dialog
          title=""
          :visible.sync="centerDialogVisible"
          width="70%"
          top="35vh"
          center
          :show-close="showClose"
      >
        <h1 class="tradMessage">{{this.tradeStatus}}&nbsp:&nbsp&nbsp{{this.message}}</h1>
        <img v-if="success" src="../assets/whited.png" >
        <img v-if="falserType" src="../assets/iconX.png" >
        <p>You can view the</p>
        <P>details in the order details !</P>
        <span slot="footer" class="dialog-footer">
          <button @click="gotoDetail()">COMPLETE</button>
        </span>
      </el-dialog>
    </div> <div class="toast" v-show="toastShow">{{toastText}}</div>
  </div>
</template>
<script>
                  //个别机型的适配问题
import {
  getRecharge,
  getUserInfo,
  addBalance,
  listWithdrawal,
  listRecharge,
} from "../api/main";
export default {
  data() {
    return {
      toastShow:false,
      toastText: '',
      auth: "",
      pageNum: 1,
      pageSize: 1000,
      nickName: "",
      balanceAmount: " ",
      rechareAmount: '',
      rebateTotal:'',//上面展示的返利和
      rebateAmount: "1% + 5%",
      configId:'',//用返利时加的参数
      bonusRate:'0%',//bonus返利
      rate:'0%',//另一种返利
      totalAmount:'',//显示的总的金额加上返利
      amountMax:'',//返利区间的最大金额
      amountMin:'',//返利区间的最小金额
      signboard:"s",//金额是否在返利区间中
      mainHeight: "",
      centerHeight: "",
      topHeight: "",
      centerDialogVisible: false,
      amountList: [],
      configList:[],
      paymentLink: "",
      orderType: "",
      message: "",
      minAmount: 10,
      maxAmount: 200000,
      success:'',
      falserType:'',
      showClose:false,
      appLink:'',
      errorMsg:'',
      tradeStatus:'',
      loading:false
    };
  },
  created() {
    this.loading=true
    let windowHeight = document.documentElement.clientHeight;
    let windowWidth = document.documentElement.clientWidth;
    this.mainHeight = windowHeight + "px";
    this.centerHeight = (windowHeight * 10) / windowWidth - 7.1 + "rem";
    if (windowHeight > 736) {
      this.topHeight = "5rem";
    } else {
      this.topHeight = "1.6rem";
    }
    //首次进来和再次进来的调整
    this.success = this.$route.query.success;
    this.falserType=!this.success
    this.message = this.$route.query.message
    this.orderType = this.$route.query.orderType;
    let queryAuth=this.$route.query.auth
    if(queryAuth!=null){
      this.auth = queryAuth;
      window.localStorage.setItem("auth", this.auth);
      getUserInfo(this.auth).then((res) => {
        this.appLink=res.data.app_link
        let data = JSON.stringify(res.data);
        window.localStorage.setItem("data", data);
        this.nickName = JSON.parse(
            window.localStorage.getItem("data")
        ).nickname;
        this.balanceAmount =
            "Balance:" +
            JSON.parse(
                window.localStorage.getItem("data")
            ).balance.toLocaleString();
      });
      getRecharge(this.auth).then((res) => {

        let dataResult = JSON.stringify(res.data.options);
        let config = JSON.stringify(res.data.config);
        window.localStorage.setItem("dataResult", dataResult);
        window.localStorage.setItem("config", config);
        this.configList=JSON.parse(
            window.localStorage.getItem("config")
        )
        this.loading=false
        this.amountList = JSON.parse(
            window.localStorage.getItem("dataResult")
        ).recharge_options;
      });
    }else{
      let auth=window.localStorage.getItem("auth");
      if(auth!=null){
        this.auth = auth;
        window.localStorage.setItem("auth", this.auth);
        //充值回来重新拉数据
        getUserInfo(this.auth).then((res) => {
          this.appLink=res.data.app_link
          let data = JSON.stringify(res.data);
          window.localStorage.setItem("data", data);
          this.nickName = JSON.parse(
              window.localStorage.getItem("data")
          ).nickname;
          this.balanceAmount =
              "Balance:" +
              JSON.parse(
                  window.localStorage.getItem("data")
              ).balance.toLocaleString();
        });
        getRecharge(this.auth).then((res) => {
          let dataResult = JSON.stringify(res.data.options);
          let config = JSON.stringify(res.data.config);
          window.localStorage.setItem("dataResult", dataResult);
          window.localStorage.setItem("config", config);
          this.configList=JSON.parse(
              window.localStorage.getItem("config")
          )
          this.loading=false
          this.amountList = JSON.parse(
              window.localStorage.getItem("dataResult")
          ).recharge_options;
        });
      }
      // this.auth = "3LQQfOK3xu06jlSL8n82Lw";
      // this.auth = "qWB8kTh9c_I5kWO1NWPLyA";
      // this.auth = "KOW4fIctUyBZzOYMZtW4ig";
    }

    if (window.localStorage.getItem("dataResult") === null) {
      getRecharge(this.auth).then((res) => {
        let dataResult = JSON.stringify(res.data.options);
        let config = JSON.stringify(res.data.config);
        window.localStorage.setItem("dataResult", dataResult);
        window.localStorage.setItem("config", config);
        this.configList=JSON.parse(
            window.localStorage.getItem("config")
        )
        this.loading=false
        this.amountList = JSON.parse(
            window.localStorage.getItem("dataResult")
        ).recharge_options;
      });
      getUserInfo(this.auth).then((res) => {
        this.appLink=res.data.app_link
        let data = JSON.stringify(res.data);
        window.localStorage.setItem("data", data);
        this.nickName = JSON.parse(
            window.localStorage.getItem("data")
        ).nickname;
        this.balanceAmount =
            "Balance:" +
            JSON.parse(
                window.localStorage.getItem("data")
            ).balance.toLocaleString();
      });
    } else {
      this.amountList = JSON.parse(
          window.localStorage.getItem("dataResult")
      ).recharge_options;
      this.nickName = JSON.parse(
          window.localStorage.getItem("data")
      ).nickname;
      //保证充值的实时性
      // this.balanceAmount =
      //     "Balance:" +
      //     JSON.parse(
      //         window.localStorage.getItem("data")
      //     ).balance.toLocaleString();
    }
  },
  mounted() {
    this.slideBanner()
    if (this.success === 'true'){
      this.success=true
      this.falserType=false
      this.centerDialogVisible=true
    }
    else if(this.success === 'false'){
      this.success=false
      this.falserType=true
      this.centerDialogVisible=true
    }
    listWithdrawal(this.auth, this.pageNum, this.pageSize).then((res) => {
      let data = JSON.stringify(res.data);
      let page = JSON.stringify(res.page);
      window.localStorage.setItem("withdrawalList", data);
      window.localStorage.setItem("wpageList", page);
    });
    listRecharge(this.auth, this.pageNum, this.pageSize).then((res) => {
      let data = JSON.stringify(res.data);
      let page = JSON.stringify(res.page);
      window.localStorage.setItem("rechargeList", data);
      window.localStorage.setItem("rpageList", page);
    });

    if(this.success===true){
      this.tradeStatus='Submitted'
    }else{
      this.tradeStatus='Failed'
    }
  },
  watch:{
    rechareAmount:{
      handler(newName,oldName){
        this.activeAmount(newName)
      },
      deep: true,
      immediate: true
    },
  },
  computed: {},
  methods: {
    clickRecharge(){
      gtag('event', 'clickRecharge')
    },
    clickImg(){
      gtag('event', 'clickImg')
    },
    //提示的方法
    toast(str) {
      let v = this
      v.toastText = str
      v.toastShow = true
      setTimeout(function() {
        v.toastShow = false
      }, 1500)
    },
    binxs: function(e) {
      this.toast(e)
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {

        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },
    activeAmount(rechareAmount) {
      this.signboard='fasle'
      this.rechareAmount = rechareAmount;
      this.rate="0%"
      this.bonusRate="0%"
      gtag('event', 'clickRecommend')
      if(this.rechareAmount>=10&&this.rechareAmount<=2000000){
        this.configList.forEach((item,i)=>{
          if(this.rechareAmount>=(parseInt(item.amount_min))&&this.rechareAmount<(parseInt(item.amount_max))){
            this.signboard=true
            this.bonusRate=item.bonus_rate*100+"%"
            this.rate=item.rate*100+'%'
            this.configId=item.id
            this.totalAmount=parseInt(this.rechareAmount)+(parseFloat(item.rate)*parseInt(this.rechareAmount)+parseFloat(item.bonus_rate)*parseInt(this.rechareAmount))
          }
          else if(this.signboard!=true){
            this.totalAmount=this.rechareAmount
          }
        })
      }else if(this.rechareAmount<10||this.rechareAmount>200000){
        // this.binxs('The amount must be between 10 and 200,000')
        this.totalAmount=this.rechareAmount
      }
      else{
        this.totalAmount=this.rechareAmount
      }
    },

    confirm() {
      if (
          this.rechareAmount >= this.minAmount &&
          this.rechareAmount <= this.maxAmount
      ) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        addBalance(this.auth, this.rechareAmount,this.configId).then((res) => {

          if(res.code!=200){
            this.errorMsg=res.msg
            this.binxs(this.errorMsg)
            setTimeout(() => {
              console.log('123')
              loading.close();
            }, 1000);
          }
          gtag('event', 'getChips')
          this.paymentLink = res.data.paymentLink;
          window.location.href = this.paymentLink;
          setTimeout(() => {
            loading.close();
          }, 1000);
        });
      } else {
        this.binxs('The amount must be between 10 and 200,000')
      }
    },
    viewDetail() {
      gtag('event', 'clickDetail')
      this.$router.push({
        path: "/detail",
      });
    },
    viewWithdrawal() {
      gtag('event', 'clickWithdraw')
      this.$router.push({
        path: "/withdrawal",
      });
    },
    gotoDetail() {
      this.centerDialogVisible = false;
    },
    backApp() {
      gtag('event', 'clickReturn')
      let backUrl = this.appLink;
      window.location.href = backUrl;
    },
  },
};
</script>
<style lang="less" scoped>
.toast {
  position: fixed;
  z-index: 2000;
  left: 5rem;
  top: 7.9rem;
  transition: all .5s;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  color: #FFF;
  background: #cc1414;
  height: 1rem;
  line-height: 1rem;
  font-size: .42rem;
  padding: 0 15px;
  width: 9.4rem;
  border-radius: .2rem;
}
/deep/.el-carousel__button{
  border-radius: 50%;
  height: .2rem;
  width: .2rem;
  background-color: #cc1316;
}
.main {
  box-sizing: border-box;
  background-color: #e5e5e5;

  .header {
    text-align: center;
    height: 4.5rem;
    .el-carousel__container {
      width: 100%;
      height: 4.4rem;
      .el-carousel--horizontal {
        padding-bottom: 0.2rem;
        width: 100%;
      }
      .el-carousel__item {
        width: 100%;
        height: 100%;
        img {
          margin: 0.2rem 0.2rem 0.2rem 0.2rem;
          width: 9.6rem;
          border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
          height: 3.8rem;
        }
      }
    }
  }
  .pannelList {
    width: 100%;
    height: 2.2rem;
    background-color: #cc1316;
    color: #eeb5af;
    display: flex;
    justify-content: space-around;
    .pannelList_item {
      width: 25%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
      //border: .01rem solid white;
      img {
        width: 1.5rem;
        height: 1.3rem;
      }
      span {
        font-size: 0.4rem;
      }
    }
  }
  .center {
    background-color: #fff;
    margin: 0.4rem 0.15rem 0 0.15rem;
    border-radius: 0.2rem 0.2rem 0 0;
    .center_nickName {
      font-size: 0.4rem;
      color: #8a878a;
      padding: 0.3rem 0.2rem 0.1rem 0.2rem;
      display: flex;
      justify-content: space-between;
      .balanceRight {
        width: 5rem;
        border: 0.01rem solid #cacdd1;
        border-radius: 0.4rem;
        padding-left: 0.2rem;
        height: 0.66rem;
        font-size: 0.4rem;
        color: #8a878a;
        overflow: hidden;
      }
    }

    .center_rechareAmount {
      //margin-bottom: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .rechareAmount_itemActive {
        color: #e57474 !important;
        text-align: center;
        margin-top: 0.4rem;
        width: 2.9rem;
        height: 2rem;
        border: 0.01rem solid #e57373 !important;
        background-color: #fff !important;
        border-radius: 0.2rem;
        p {
          font-size: 0.6rem;
          height: 2rem;
          line-height: 2rem;

        }
        span{
          position: relative;
          top: -1.4rem;
          font-size: 0.5rem;
          color: #e57474 !important;
        }
      }
      .rechareAmount_item {
        color: aliceblue;
        text-align: center;
        margin-top: 0.4rem;
        width: 2.9rem;
        height: 2rem;
        background-color: #e57373;
        border-radius: 0.2rem;
        p {
          font-size: 0.6rem;
          height: 2rem;
          line-height: 2rem;
           margin-bottom: 0rem!important;
        }
        span{
          position: relative;
          top: -.8rem;
          font-size: 0.5rem;
          color: aliceblue;
        }
      }
    }
    .reminder{
      margin-top: 0.2rem;
      padding-left: 3.96rem;
      padding-bottom: .1rem;
      .reminder_bonus{
        margin-left: 1.02rem;
      }
      span{
        font-size: .34rem;
        //margin-right: .98rem;
      }
    }
    .center_custom {
      display: flex;
      justify-content: space-around;
      font-size: 0.5rem;
      text-align: center;
      color:#3a3f47;
      input {
        color: #3a3f47;
        padding-left: 0.1rem;
        width: 3rem;
        height: 0.8rem;
        border: 0.01rem solid #e1e3e4;
        font-size: 0.3rem;
      }
      .rebate {
        border: 0.01rem solid #e1e3e4;
        width: 1.2rem;
        height: .83rem;
        line-height: .83rem;
        font-size: 0.34rem;
        margin-bottom: 0rem;
      }
      .amountTotal {
        width: 2.2rem;
        overflow: hidden;
      }
    }
    .center_bottom {
      text-align: center;
      margin-top: 2rem;
      button {
        width: 6rem;
        height: 1.2rem;
        border: none;
        color: #fff;
        font-size: 0.5rem;
        background-color: #cc1316;
        border-radius: 0.2rem;
      }
    }
  }

}
.dialog {
  /deep/ .el-dialog--center .el-dialog__body{
    //height: 3rem;
    max-height: 8.4rem;
  }
  /deep/ .el-dialog__header{
    background-color: #cc1414;
    border-radius: 0.2rem 0.2rem 0 0 ;
    padding-top: 1.8rem;
    /deep/.el-dialog__headerbtn {
      left: 0.27rem !important;
    }

    /deep/ .el-dialog__body {
      height: 3.4rem;
      max-height: 5.4rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
    /deep/ .el-dialog__headerbtn .el-dialog__close{
      color: #ffffff !important;
      width: 1rem;
      height: 1rem;
    }
    /deep/ .el-icon-close:before{
      content: '';
    }
  }
  /deep/ .el-dialog--center {
    border-radius: 0.2rem;
  }
  img{
    //width: 1.6rem;
    height: 1.3rem;
    position: absolute;
    top: .38rem;
    left: 2.8rem;
  }
  h1 {
    text-align: center;
    font-size: 0.44rem;
    color: #e56e6e;
    //height: 0.6rem;
    max-height: 2.4rem;
    //line-height: 0.6rem;
  }
  p {
    text-align: center;
    font-size: 0.42rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin-bottom: 0rem;
  }
  button {
    width: 3rem;
    height: 0.8rem;
    border: none;
    border-radius: 0.15rem;
    background-color: #cc1414;
    font-size: .4rem;
    color: white;
  }
}
</style>
