<template>
  <div class="main"
       :style="{height:mainHeight}">
    <div class="main-top">
      <button @click="back()"><img src="../assets/left.png"></button>
      <p>Withdraw</p>
    </div>
    <div class="header">

      <div class="top">
        <p class="">Information description</p>
      </div>
      <div class="content">
        <p> <span class="contentDefault" :class="{content_left:(account.realname!='')}"><img src="../assets/icon_g.png" v-if="account.realname">Name</span><span class="content_right">{{account.realname}}</span> </p>
        <p> <span class="contentDefault" :class="{content_left:(account.pan_no!='')}"><img src="../assets/icon_g.png" v-if="account.pan_no">Pan NO</span><span class="content_right">{{account.pan_no}}</span> </p>
        <p> <span class="contentDefault" :class="{content_left:(account.email!='')}"><img src="../assets/icon_g.png" v-if="account.email">Email</span><span class="content_right">{{account.email}}</span> </p>
        <p> <span class="contentDefault" :class="{content_left:(account.upi!='')}"><img src="../assets/icon_g.png" v-if="account.upi">UPI</span><span class="content_right">{{account.upi}}</span> </p>
        <p> <span class="contentDefault" :class="{content_left:(account.ifsc!='')}"><img src="../assets/icon_g.png" v-if="account.ifsc">IFSC Code</span><span class="content_right">{{account.ifsc}}</span> </p>
        <p> <span class="contentDefault" :class="{content_left:(account.bank_no!='')}"><img src="../assets/icon_g.png" v-if="account.bank_no">Bank Account</span><span class="content_right">{{account.bank_no}}</span> </p>
      </div>
    </div>
    <div class="withdrawal-Amount">
      <p class="withdrawal_amount">Withdraw amount : {{amount}} </p>
      <img src="../assets/btn_th.png"
           @click="clickTips()">
    </div>

    <div class="Amount">
      <button class="amount_btn">₹</button>
      <input type="text"
             class="inputAmount"
             prefix-icon="currency"
             placeholder="The amount should be higher than 100."
             oninput="value=value.replace(/[^\d]/g,'')"
             v-model="withDrawalAmount">
    </div>
    <div class="center_bottom"
         :style="{marginTop: topHeight}"
         @click="confirm()">
      <button>Submit</button>
    </div>
<!--    tips   -->
    <div class="drawer">
      <el-drawer title=""
                 :visible.sync="drawer"
                 size="48%"
                 direction="btt">
        <p class="tips_p">TIPS</p>
        <div class="tips-item">
          <div><img src="../assets/icon_ht.png"></div>
          <p> To avoid withdrawal failure, please confirm that all the information is correct before you submit (to revise information, please return to the game).</p>
        </div>
        <div class="tips-item">
          <div><img src="../assets/icon_ht.png"></div>
          <p>Request anytime. Payments will be processed within 24 hours between 9:00 and 18:00 on weekdays. </p>
        </div>
        <div class="tips-item">
          <div><img src="../assets/icon_ht.png"></div>
          <p>The withdrawal amount should be higher than 100.</p>
        </div>
        <div class="tips-item">
          <div><img src="../assets/icon_ht.png"></div>
          <p> Feel free to contact our customer service anytime for any inquiries!</p>
        </div>
        <div class="tips-item">
          <div><img src="../assets/icon_ht.png"></div>
          <p>You can withdraw up to Rs.10000.00 per transaction for NO more than 3 times a day.</p>
        </div>
      </el-drawer>
    </div>
<!--   dialog   -->
    <div class="dialog">
      <el-dialog
          title=""
          :visible.sync="centerDialogVisible"
          width="70%"
          top="35vh"
          center
          :show-close="showClose"
      >
        <h1 class="tradMessage">{{this.tradeStatus}}&nbsp:&nbsp&nbsp{{this.statusMessage}}</h1><br>
        <img v-if="(this.statusCode===200)" src="../assets/whited.png" >
        <img v-if="!(this.statusCode===200)" src="../assets/iconX.png" >
        <p>You can view the</p>
        <P>details in the order details !</P>
        <span slot="footer" class="dialog-footer">
          <button @click="gotoDetail()">COMPLETE</button>
        </span>
      </el-dialog>
    </div>
    <div class="toast" v-show="toastShow">{{toastText}}</div>
  </div>
</template>
<script>
import {getRecharge, getUserInfo, lessBalance} from '../api/main'
export default {
  data () {
    return {
      toastShow:false,
      toastText: 'Please enter the CORRECT INFO!',
      mainHeight: '',
      centerHeight: '',
      topHeight: '',
      amount: 0,
      account: {
        realname: '',
        pan_no: '',
        email: '',
        upi: '',
        ifsc: '',
        bank_no: ''
      },
      withDrawalAmount: '',
      drawer: false,
      centerDialogVisible:false,
      auth: '',
      orderType:'',
      message:'',
      statusCode:'',
      statusMessage:'',
      success:'',
      falserType:'',
      showClose:false,
      tradeStatus:'',
      errorList: {
        "400":"The Token Has Expired, Please Login Again.",
        "600": "The Phone Number Has Been Used.",
        "700": "The Facebook Account Has Been Used.",
        "101001": "The Cell Phone Number Cannot be Empty.",
        "101002": "The Device ID Cannot be Empty.",
        "101003": "The Verification Code Cannot be Empty.",
        "101004": "Your Verification Code Has Been Sent, Please Check.",
        "101005": "Failed to Send the Verification Code.",
        "102001": "Please Enter Your Password.",
        "102002": "Wrong Format. Please Enter 4-12 Digits Numbers or Letters.",
        "102003": "Incorrect Account or Password.",
        "102004": "Incorrect Account or Password.",
        "102005": "The Phone Number Has Been Used.",
        "102006": "You Have Registered Too Many Accounts On This Device.",
        "102007": "Incorrect Verification Code.",
        "102008": "The Device ID Cannot be Empty.",
        "102009": "AppPackageName Cannot be Empty.",
        "102010": "AppVersion Cannot be Empty.",
        "102011": "You Are Only Allowed to Change Your Nickname Once a Month.",
        "102012":"Facebook Login Failed.",
        "102013": "The Phone Number Has Been Used.",
        "102014": "You Can Only Link One Facebook Account.",
        "102015": "Login Failed, Please Contact Customer Service.",
        "102016": "The Inviter Does Not Exist.",
        "102017": "The Inviter Does Not Exist.",
        "102018": "The Inviter Does Not Exist.",
        "102019": "You can only link one Google account",
        "102020": "Google login failed",
        "102021": "The Google account has been used",
        "103001": "The Email Cannot be Empty.",
        "103002": "The IFSC Code Cannot be Empty.",
        "103003": "The Bank Account Cannot be Empty.",
        "103004": "The Name Cannot be Empty.",
        "103005": "The PAN Card Cannot be Empty.",
        "103006": "The Cell Phone Number Cannot be Empty.",
        "103007": "Wrong Account Info, Please Confirm and Re-enter.",
        "103008": "This PAN Card Has Been Used By Another Account.",
        "103009": "This Bank Account Has Already Been Used, Please Check Again.",
        "103010": "The AppPackageName Cannot be Empty.",
        "103011": "The AppVersion Cannot be Empty.",
        "103012": "Incorrect Recharge Amount, Please Check.",
        "103013": "System Error, Please Try Again.",
        "103014": "System Error, Please Try Again.",
        "103017": "Please Add Your Bank Account First.",
        "103019": "The Cell Phone Number Cannot Be Empty.",
        "103020": "The Email Cannot Be Empty.",
        "103021": "PayChannel Cannot Be Empty.",
        "103022": "Please Enter Your Name.",
        "103023": "The Gift Bag Has Expired.",
        "103024": "Wrong Gift Bag Amount.",
        "103025": "Purchase to Reach the Upper Limit.",
        "103026": "The Gift Bags are Sold Out.",
        "103029": "Event Has Ended or Purchase Exceeded the Limit, Please Come Back Later.",
        "104001": "The MailID Cannot be Empty.", "104002": "Invalid Request.",
        "104003": "Claim Failed, Please Try Again.",
        "104004": "Claim Failed, Please Try Again.",
        "105001": "The Invite Code Cannot be Empty.",
        "105002": "You Have Added An Inviter.",
        "105003": "The Inviter Does Not Exist.",
        "105004": "Invalid Request.",
        "105005": "You Can't Invite Yourself.",
        "105006": "Verify Your Phone Number"
      }
    }
  },
  created () {
    this.auth=window.localStorage.getItem('auth')
    let windowHeight = document.documentElement.clientHeight
    // let windowWidth = document.documentElement.clientWidth
     // this.centerHeight = (windowHeight * 10 / windowWidth - 7.1) + 'rem'
    this.mainHeight = windowHeight + 'px'
   
    if (windowHeight > 736) {
      this.topHeight = '9rem'
    } else {
      this.topHeight = '5.6rem'
    }
  },
  mounted () {
    if(this.orderType===true){
      this.centerDialogVisible=true
    }
    let data=JSON.parse(window.localStorage.getItem('data'))
    this.amount = data.amount.toLocaleString()
    this.account = data.account

    if(this.statusCode===200){
      this.tradeStatus='Submitted'
    }else{
      this.tradeStatus='Failed'
    }
  },

  computed: {
  },
  methods: {
    clickTips(){
      this.drawer = true
      gtag('event', 'clickTips')
    },
    toast(str) {
      let v = this
      v.toastText = str
      v.toastShow = true
      setTimeout(function() {
        v.toastShow = false
      }, 1500)
    },
    binxs: function(e) {
      this.toast(e)
    },

    confirm () {
      gtag('event', 'clickSubmit')
      if(this.account.pan_no!=null||this.account.pan_no!=undefined||this.account.pan_no!=''){
        let dataAmount=JSON.parse(window.localStorage.getItem('data')).amount
        if(this.withDrawalAmount===''||this.withDrawalAmount==='0'||this.withDrawalAmount<100){
          this.binxs('The amount should be higher than 100.')
        }else{
          if( dataAmount < this.withDrawalAmount){
            // this.toastText='Please enter the CORRECT INFO!'
            this.binxs('Insufficient Balance')
          }else{
            lessBalance(this.auth, this.withDrawalAmount).then((res) => {
              this.statusCode=res.code
              this.statusMessage=res.msg
              if (this.statusCode===200){
                getRecharge(this.auth).then((res) => {
                  let dataResult = JSON.stringify(res.data.options);
                  let config = JSON.stringify(res.data.config[0]);
                  window.localStorage.setItem("dataResult", dataResult);
                  window.localStorage.setItem("config", config);
                  this.amountList = JSON.parse(
                      window.localStorage.getItem("dataResult")
                  ).recharge_options;
                  this.rechareAmount = JSON.parse(
                      window.localStorage.getItem("dataResult")
                  ).recharge_options_default;
                });

                getUserInfo(this.auth).then((res) => {
                  let data = JSON.stringify(res.data);
                  window.localStorage.setItem("data", data);
                  this.nickName = JSON.parse(
                      window.localStorage.getItem("data")
                  ).nickname;
                  this.balanceAmount =
                      "Balance:" +
                      JSON.parse(
                          window.localStorage.getItem("data")
                      ).balance.toLocaleString();
                });
              }else{
                this.centerDialogVisible=true
              }
              this.searchStatue()
            })
          }
        }
      }else if(this.account.pan_no===undefined) {
        this.binxs('Please enter the CORRECT INFO!')
      }
      if (this.account.pan_no===undefined){
        this.binxs('Please enter the CORRECT INFO!')
      }
    },
    searchStatue(){
      for(var key in this.errorList){
          let statusC= this.statusCode
        if (key === String(statusC)){
          this.statusMessage=this.errorList[key]
        }
      }
    },
    gotoDetail() {
      this.centerDialogVisible = false;
    },
    back () {
      gtag('event', 'clickBack')
      this.$router.push({
        path: "/",
        query:{
          auth:window.localStorage.getItem("auth")
        }
      });
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-drawer{
  border-radius: .25rem .25rem 0 0;
}
.toast {
  position: fixed;
  z-index: 2000;
  left: 5rem;
  top: 9rem;
  transition: all .5s;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  color: #FFF;
  background: #cc1414;
  height: 1rem;
  line-height: 1rem;
  font-size: .42rem;
  padding: 0 15px;
  width: 9rem;
  border-radius: .2rem;
}
.main {
  background-color: #e5e5e5;
  box-sizing: border-box;
  padding: 0.2rem 0.2rem 0 0.2rem;
  .main-top {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    button {
      border: none;
      // width: 1rem;
      // height: 1rem;
      padding: 0;
      background-color: #e5e5e5;
      margin-right: 3rem;
    }
    p {
      font-size: 0.45rem;
      height: 0.8rem;
      color: #7c7c7c;
      line-height: 0.8rem;
    }
  }
  .header {
    .top {
      height: 1rem;
      border-radius: 0.15rem 0.15rem 0 0;
      background-color: #bfbfbf;
      padding-left: 0.3rem;
      p {
        color: white;
        font-size: 0.4rem;
        height: 1rem;
        line-height: 1rem;
      }
    }
    .content {
      height: 5rem;
      background-color: #ffffff;
      border-radius: 0 0 0.15rem 0.15rem;
      padding: 0.15rem;
      p {
        font-size: 0.4rem;
        color: #868686;
        height: 0.8rem;
        line-height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0rem;
        img {
          margin: 0 auto;
          height: 0.4rem;
          padding-right: 0.2rem;
        }
        .content_right {
          float: right;
        }
        .content_left {
          margin-left: 0rem!important;
        }
        .contentDefault{
          margin-left: 0.8rem;
        }
      }
    }
  }
  .withdrawal-Amount {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .withdrawal_amount {
      font-size: 0.475rem;
      color: #7d7d7d;
      height: 1.4rem;
      line-height: 1.4rem;
    }
    p{
      margin-bottom: 0rem;
    }
    img {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  .Amount {
    display: flex;
    align-items: center;
    .amount_btn {
      border: none;
      height: 1.1rem;
      line-height: 1.1rem;
      width: 0.8rem;
      font-size: 0.9rem;
      color: #7e7e7e;
      background-color: #fff;
      margin: 0 !important;
      border-radius: 0.2rem 0rem 0rem 0.2rem;
      // padding-top: 0.01rem;
    }
    .inputAmount {
      width: 9rem;
      height: 1.1rem;
      border: none;
      font-size: 0.45rem;
      padding-left: 0.1rem;
      border-radius: 0rem 0.2rem 0.2rem 0rem;
    }
    input::-webkit-input-placeholder {
      color: #cacaca;
    }
    input::-ms-input-placeholder {
      color: #cacaca;
    }
  }
  .center_bottom {
    text-align: center;
    margin-top: 2rem;
    button {
      width: 6rem;
      height: 1.2rem;
      border: none;
      color: #fff;
      font-size: 0.6rem;
      background-color: #cc1316;
      border-radius: 0.2rem;
    }
  }
  .el-drawer.btt {
    position: relative;
    border-radius: 0.1rem;
    /deep/ .el-drawer__header {
      margin-bottom: 0rem !important;
    }
    .tips_p {
      text-align: center;
      font-size: 0.45rem;
      color: #808080;
      position: absolute;
      top: 0.2rem;
      left: 4.5rem;
    }
    height: 45% !important;
    .tips-item {
      display: flex;
      // justify-content: space-around;
      padding: 0 0.1rem;
      img {
        margin: 0 0.4rem 0 0.4rem;
        width: 0.3rem;
        height: 0.3rem;
      }
      p {
        // height: 0.9rem;
        line-height: 0.6rem;
        padding-right: 0.6rem;
        font-size: 0.36rem;
        color: #8b8b8b;
        margin-bottom: 0;
      }
    }
  }
}

.dialog {
  /deep/ .el-dialog--center .el-dialog__body{
    //height: 3rem;
    max-height: 8.8rem;
  }
  /deep/ .el-dialog__header{
    background-color: #cc1414;
    border-radius: 0.2rem 0.2rem 0 0 ;
    padding-top: 1.8rem;
    /deep/.el-dialog__headerbtn {
      left: 0.27rem !important;
    }

    /deep/ .el-dialog__body {
      height: 3.4rem;
      max-height: 5.4rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
    /deep/ .el-dialog__headerbtn .el-dialog__close{
      color: #ffffff !important;
      width: 1rem;
      height: 1rem;
    }
    /deep/ .el-icon-close:before{
      content: '';
    }
  }
  /deep/ .el-dialog--center {
    border-radius: 0.2rem;
  }
  img{
    //width: 1.6rem;
    height: 1.3rem;
    position: absolute;
    top: .38rem;
    left: 2.8rem;
  }
  h1 {
    text-align: center;
    font-size: 0.44rem;
    color: #e56e6e;
    //height: 0.6rem;
    max-height: 2.8rem;
    //line-height: 0.6rem;
  }
  p {
    text-align: center;
    font-size: 0.42rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin-bottom: 0rem;
  }
  button {
    width: 3rem;
    height: 0.8rem;
    border: none;
    border-radius: 0.15rem;
    background-color: #cc1414;
    font-size: .4rem;
    color: white;
  }
}
</style>
