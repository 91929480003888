import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from "moment";
import 'lib-flexible/flexible'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
// import $ from "jquery"
// window.$ = $
// import Vue from 'vue';
import { Message } from 'element-ui';
//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 60
  });
};

//重写一遍success的方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 60
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;



Vue.config.productionTip = false    //在VUE中使用了JQ
Vue.prototype.$moment=moment
Vue.use(ElementUI, { locale },)
Vue.use(Antd)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
