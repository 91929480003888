import ajax from './index'
// let BASR_URL = process.env.VUE_APP_URL
// let Url=window.location.host
// console.log(Url+'/api/')
// let BASR_URL = 'http://34.93.109.166/api/'
let BASR_URL = '/api/'
let getuserInfoURL = BASR_URL + 'user/info'
let getRechargeURL = BASR_URL + 'global-config/recharge'
let addBalanceURL = BASR_URL + 'payment/balance-add'
let lessBalanceURL = BASR_URL + 'payment/balance-less'
let listWithdrawalURL = BASR_URL + 'payment/balance-less/list'
let listRechargeURL = BASR_URL + 'payment/balance-add/list'
export const getUserInfo = (auth) => ajax(getuserInfoURL.toString(), { auth }, 'post');
export const getRecharge = (auth) => ajax(getRechargeURL.toString(), { auth }, 'post');
export const addBalance = (auth, amount,configId) => ajax(addBalanceURL.toString(), { auth, amount,configId }, 'post');
export const lessBalance = (auth, amount) => ajax(lessBalanceURL.toString(), { auth, amount }, 'post');
export const listWithdrawal = (auth, page, limit,start_date,end_date) => ajax(listWithdrawalURL.toString(), { auth, page, limit, start_date,end_date}, 'post');
export const listRecharge = (auth, page, limit,start_date,end_date) => ajax(listRechargeURL.toString(), { auth, page, limit,start_date,end_date }, 'post');


//打包的时候要注意URL的更换

