import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import detail from '../views/detail.vue'
import withdraw from '../views/withdraw.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  }, {
    path: '/withdrawal',
    name: 'withdraw',
    component: withdraw
  },
]

const router = new VueRouter({
  // mode: "history",
  routes
})

export default router
