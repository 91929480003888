<template>
  <div class="main"
       :style="{height:mainHeight}" >
    <div class="main-top">
      <button @click="back()"><img src="../assets/left.png"></button>
      <p>Detail page</p>
    </div>
    <div class="header">
      <div class="header-item item01" @click="clickTotal()">
        <p>Total Recharge</p>
        <p>₹  {{totalRecharge}}</p>
      </div>
      <div class="header-item item">
        <p>|</p>
      </div>
      <div class="header-item item02">
        <p>Total Withdrawal</p>
        <p>₹ {{totalWithdrawal}}</p>
      </div>
      <div class="header-item item ">
        <p>|</p>
      </div>
      <div class="header-item item03">
        <p>Total Rebate</p>
        <p>₹ {{totalRebate}}</p>
      </div>
    </div>
    <div v-loading="loading"     element-loading-text="loading"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="search"  >
        <el-button :class="{searchActive:dateActive}"
                   @click="showModal"><span>Date</span> <img src="../assets/xa.png"></el-button>
        <button :class="{searchActive:rechargeActive}"
                @click="searchRecharge()">Recharge</button>
        <button :class="{searchActive:withdrawalActive}"
                @click="searchWithdrawal()">Withdrawal</button>
      </div>
      <div class="list"
           :style="{height:listHeight}"
           element-loading-text="Hard loading"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="listList">
          <div class="list_item" v-for="item in dataList" :key="item.id">
            <div class="item_top">
              <p class="Date">{{item.created_at}}</p>
            </div>
            <div class="item_bottom">
              <p class="tradeType">
                {{searchName}}
                <img v-if="item.status===1&&searchName==='Recharge'" src="../assets/withdrawIcon.png" >
                <img v-if="item.status===0 ||item.status===2&&searchName==='Recharge'" src="../assets/padding.png" >
                <img v-if="item.status===3&&searchName==='Recharge'" src="../assets/rechargeIcon.png">
                <span class="paddingStatus" v-if="item.status===0 ||item.status===2&&searchName==='Recharge'">Pending </span>
                <span class="successfulStatus" v-if="item.status===1&&searchName==='Recharge'">Successful </span>
                <span class="failedStatus" v-if="item.status===3&&searchName==='Recharge'">Failed </span>
                <img v-if="item.status===1&&searchName==='Withdraw'" src="../assets/withdrawIcon.png" >
                <img v-if="item.status===3&&searchName==='Withdraw'" src="../assets/rechargeIcon.png" >
                <img v-if="item.status===2&&searchName==='Withdraw'"src="../assets/padding.png" >
                <span v-if="item.status===0&&searchName==='Withdraw'">Reviewing </span>
                <span class="successfulType" v-if="item.status===1&&searchName==='Withdraw'">Successful</span>
                <span class="SubmittedStatus" v-if="item.status===2&&searchName==='Withdraw'">Submitted</span>
                <span class="failedStatus" v-if="item.status===3&&searchName==='Withdraw'">Failed </span>
              </p>
              <p v-if="typeTrade" :class="{trade_recharge:typeTrade,trade_withdrawal:typeTrade===false}" >+ ₹ {{item.amount}}</p>
              <p v-if="!typeTrade" :class="{trade_recharge:typeTrade,trade_withdrawal:typeTrade===false}" >- ₹ {{item.amount}}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div ref="moadl">
      <a-modal v-model:visible="visible" title="Date of the search" @ok="handleOk" :dialogStyle="dialogStyle">
        <a-range-picker @change="onChange">
          <template slot="dateRender" slot-scope="current">
            <div class="ant-calendar-date" :style="getCurrentStyle(current)" style="{height: .8rem}">
              {{ current.date() }}
            </div>
          </template>
        </a-range-picker>
        <template #footer>
          <a-button key="submit"  type="primary" danger    v-loading="loading" @click="handleOk">Confirm</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>
<script>
import {  listWithdrawal,listRecharge } from '../api/main'
import { defineComponent, ref } from 'vue';
import withdraw from "./withdraw";
export default {
  data () {
    return {
      auth:'',
      date:'',
      mainHeight: '',
      centerHeight: '',
      topHeight: '',
      listHeight: '',
      totalRecharge: '',
      totalWithdrawal: '',
      totalRebate: '',
      dateActive: false,
      rechargeActive: true,
      withdrawalActive: false,
      typeTrade: true,//true is Recharge
      total: 0,
      pageNum: 0,
      pageSize: 1000,
      searchName:'Recharge',
      rechargeList:[],
      withdrawalList:[],
      dataList:[],
      loading:false,
      centerDialogVisible:false,
      visible:false,
      rechargePagenum:'',
      withdrawPagenum:'',
      dialogStyle:{
        top:"4.2rem"
      }
    }
  },
  created () {
    this.auth=window.localStorage.getItem('auth')
    let windowHeight = document.documentElement.clientHeight
    let windowWidth = document.documentElement.clientWidth
    this.mainHeight = windowHeight + 'px'
    this.listHeight = (windowHeight / windowWidth * 10 - 4.33) + 'rem'
    if (windowHeight === 736) {
      this.listHeight = (windowHeight / windowWidth * 10 - 4.33) + 'rem'
    }
    if (windowHeight > 736) {
      this.topHeight = '9rem'
    } else {
      this.topHeight = '5.6rem'
    }
  },
  mounted(){
    //从浏览器拿到金额等数据
    this.totalRecharge=JSON.parse(window.localStorage.getItem('data')).recharge_amount
    this.totalWithdrawal=JSON.parse(window.localStorage.getItem('data')).withdraw_amount
    this.totalRebate=JSON.parse(window.localStorage.getItem('data')).rebate_amount
    // 首次进入detail默认展示rechargeList
    if(window.localStorage.getItem('rechargeList')!==null){
      this.dataList = JSON.parse(window.localStorage.getItem('rechargeList'))
      this.total=JSON.parse(window.localStorage.getItem('rpageList')).count
    }
  },
  computed: {
  },
  watch:{
    rechargePagenum:{
      handler(newName,oldName){
       this.changePageNum()
      }
    },
    withdrawPagenum:{
      handler(newName,oldName){
        this.changePageNum()
      }
    },
    pageSize:{
      handler(newName,oldName){
        if(this.pageSize>this.total){
          this.$message.error("The selected page number does not support paging")
        }else{
          this.changePageSize()
        }
      }
    }
  },
  methods: {
    clickTotal(){
      gtag('event', 'clickTotal')
    },
    changePageSize(){
      this.loading=true
      if(this.typeTrade===true){
        this.reList()
      }else{
        this.whList()
      }
    },
    changePageNum(){
      this.loading=true
      if(this.typeTrade===true){
        this.reList()
      }else{
        this.whList()
      }
    },
    showModal (){
      this.visible = true;
    },
    handleOk (){
      this.visible = false;
      this.searchTime()
    },
    onChange(date, dateString) {
      this.date=dateString
    },
    getCurrentStyle(current, today) {
      const style = {};
      if (current.date() === 1) {
        style.border = '1px solid #1890ff';
        style.borderRadius = '50%';
      }
      return style;
    },
    searchDate () {
      gtag('event', 'searchDate')
      this.dateActive = true
      this.rechargeActive = false
      this.withdrawalActive = false
      this.centerDialogVisible=true
    },
    searchTime(){
      if(this.typeTrade===true){
        this.reList()
      }else{
        this.whList()
      }
      this.centerDialogVisible=false
    },
    searchRecharge () {
      gtag('event', 'searchRecharge')
      this.searchName="Recharge"
      this.dateActive = false
      this.rechargeActive = true
      this.withdrawalActive = false
      this.typeTrade=true
      if(window.localStorage.getItem('rechargeList')!==null){
        this.dataList = JSON.parse(window.localStorage.getItem('rechargeList'))
        let rechargeTotal= JSON.parse(window.localStorage.getItem('rpageList')).count
        this.total=rechargeTotal
        this.pageNum=this.rechargePagenum
      }
      // if(this.date){
      //   this.reList()
      //   console.log(this.date)
      // } else{
      //   this.loading=false
      // }
      // if(this.pageNum!=1||this.pageSize!=5){
      //   this.reList()
      // }else{
      //
      // }
      this.loading=false
    },
    reList(){
      let beginDateStr = this.date[0]
      let endDateStr = this.date[1]
      listRecharge(this.auth,this.rechargePagenum,this.pageSize,beginDateStr,endDateStr).then((res)=>{
        this.loading=false
        this.dataList=res.data
        this.dataList=res.data
        this.total=res.page.count
        this.pageNum=res.page.current_page
        this.pageSize=res.page.item_count
        let data = JSON.stringify(res.data);
        let page = JSON.stringify(res.page);
        window.localStorage.setItem("rechargeList", data);
        window.localStorage.setItem("rpageList", page);
      })
    },
    whList(){
      let beginDateStr = this.date[0]
      let endDateStr = this.date[1]
      listWithdrawal(this.auth,this.withdrawPagenum,this.pageSize,beginDateStr,endDateStr).then((res)=>{
        this.loading=false
        this.dataList=res.data
        this.total=res.page.count
        this.pageNum=res.page.current_page
        this.pageSize=res.page.item_count
        let data = JSON.stringify(res.data);
        let page = JSON.stringify(res.page);
        window.localStorage.setItem("withdrawalList", data);
        window.localStorage.setItem("wpageList", page);
      })
    },
    searchWithdrawal () {
      gtag('event', 'searchWithdrawal')
      this.searchName="Withdraw"
      this.dateActive = false
      this.rechargeActive = false
      this.withdrawalActive = true
      this.typeTrade=false //用于判断提现的标志
      if(window.localStorage.getItem('withdrawalList')!=null){
        let withdrawList=JSON.parse(window.localStorage.getItem('withdrawalList'))
        this.dataList = withdrawList
        let withdrawTotal= JSON.parse(window.localStorage.getItem('wpageList')).count
        this.total=withdrawTotal
        this.pageNum=this.withdrawPagenum
      }
    },
    handleSizeChange (val) {
      this.pageSize=val
    },
    handleCurrentChange (val) {
      this.pageNum=val
      if(this.typeTrade===true){
        this.rechargePagenum=this.pageNum
        this.reList()
      }else{
        this.withdrawPagenum=this.pageNum
        this.whList()
      }
    },
    back () {
      gtag('event', 'clickBack')
      this.$router.push({
        path: "/",
        query:{
          auth:window.localStorage.getItem("auth")
        }
      });
    }
  },
}
</script>
<style lang="less" scoped>
/deep/.el-pagination{
  font-size: .4rem
}
/deep/ .block{
  font-size: .4rem
}
.main {
  background-color: #e5e5e5;
  box-sizing: border-box;
  padding: 0.2rem 0.2rem 0 0.2rem;
  .main-top {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    button {
      border: none;
      // width: 1rem;
      // height: 1rem;
      padding: 0;
      background-color: #e5e5e5;
      margin-right: 3rem;
    }
    p {
      font-size: 0.45rem;
      height: 0.8rem;
      color: #7c7c7c;
      line-height: 0.8rem;
    }
  }
  .header {
    height: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 0.15rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.35rem;
    .item {
      color: #666a6d;
      p {
        height: 0.6rem;
      }
    }
    .item01 {
      color: #35be58;
    }
    .item02 {
      color: #d54040;
    }
    .item03 {
      color: #41688d;
    }
    .header-item {
      text-align: center;
      p {
        height: 0.6rem;
        line-height: 0.6rem;
        margin-bottom: .0rem!important;
      }
    }
  }

  .search {
    height: 0.8rem;
    margin-top: 0.2rem;

    display: flex;
    justify-content: space-around;
    align-items: center; 
    /deep/ .el-date-picker{
          /deep/ .el-picker-panel__header{
      width: 6rem;
    }
      /deep/ .el-date-picker .el-picker-panel__content{
    width: 6rem;
  }
    }

    .searchActive {
      border: 0.04rem solid #eb9494;
      border-radius: 0.1rem;
      color: #eb8e8e;
      // background-color: #fff;
    }
    button {
      width: 2rem;
      padding: 0.1rem 0;
      // height: 0.6rem;
      border: none;
      color: #7e7e7e;
      font-size: 0.38rem;
      background-color: #e5e5e5;
      display: flex;
      justify-content: space-around;
      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-left: 0.2rem;
      }
    }
  }
  .list {
    margin-top: 0.2rem;
    background-color: #fff;
    border-radius: 0.2rem 0.2rem 0 0;
    overflow: scroll;
    .listList {
      .list_item {
        padding: 0.2rem 0.2rem;
        .item_top {
          p {
            font-size: 0.35rem;
            color: #858585;
            height: 0.55rem;
            line-height: 0.55rem;
            margin-bottom: 0;
          }
        }
        .item_bottom {
          display: flex;
          height: 0.8rem;
          justify-content: space-between;
          border-bottom: 0.05rem solid #e5e5e5;
          .tradeType {
            color: #858585;
          }
          .trade_recharge {
            color: #45c263;
          }
          .trade_withdrawal {
            color: #cd1f1f;
          }
          img{
            width: 1.8rem;
            height: .5rem;
          }
          p {
            font-size: 0.38rem;

            //height: 0.54rem;
            //line-height: 0.54rem;
            position: relative;
            span{
              text-align: center;
              position: absolute;
              left: 1.95rem;
              top: .088rem;
              color: #ffffff;
              font-size: .3rem;
            }
            .failedStatus{
              position: absolute;
              top: .089rem;
              left: 2.26rem;
              font-size: .3rem;
            }
            .successfulStatus{top: .088rem;
              font-size: .3rem;
            }
            .successfulType{
              position: absolute;
              left: 1.98rem;
              font-size: .3rem;
              top: .09rem;
            }
            .paddingStatus{
              position: absolute;
              left: 2.08rem;
              font-size: .3rem;
              //color: #e57373;
            }
            .SubmittedStatus{
              position: absolute;
              left: 2.02rem;
              font-size: .3rem;
            }
          }
        }
      }
    }

  }
  .dialog {
    /deep/ .el-dialog--center{
      border-radius: .2rem;
      /deep/ .el-dialog__body{
        padding:0.33rem 0.2rem 0.3rem !important ;
      }
    }
   button {
      width: 3rem;
      height: 0.8rem;
      border: none;
      border-radius: 0.15rem;
      background-color: #cc1414;
      color: white;
    }

  }

}
div ::v-deep .ant-modal{
  position: relative;
  top: 2.6rem !important;
}
::v-deep .ant-modal-title{
  height: .6rem;
  line-height: .6rem;
  text-align: center;
  font-size: .45rem;
  color: #cc1414;
}
::v-deep .ant-modal-header{
  border-radius: 0.2rem;
}
::v-deep .ant-modal-content{
  border-radius: 0.2rem;
}
::v-deep .ant-modal-body{
  text-align: center;
  padding: .3rem 0rem;
}
::v-deep .ant-modal-footer{
  text-align: center;
}

/deep/ .ant-input{
  height: .8rem;
}
div /deep/ .ant-btn{
  text-align: center;
  height: .8rem;
  width: 2rem;
  border-radius: .2rem;
  font-size: .42rem;
  color: #fff;
  background-color: #cc1414;
  border: none;
}

</style>
