import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
axios.defaults.timeout = 20000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

axios.interceptors.request.use((config) => {
  NProgress.start();
  // const token = window.localStorage.getItem('token');
  // config.headers.Authorization = token;
  return config;
}, (error) => {
  return Promise.error(error);
});

axios.interceptors.response.use((response) => {
  NProgress.done();
  if (response.status === 200) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response.data);
  }

}, (error) => {
  console.log(error);
});

export default function ajax (url = '', params = {}, type = 'GET') {
  let promise;
  return new Promise((resolve, reject) => {
    if (type.toUpperCase() === 'GET') {
      promise = axios({
        url,
        params
      })
    } else if (type.toUpperCase() === 'POST') {
      promise = axios({
        method: 'post',
        url,
        data: params
      })
    }
    promise.then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    })
  });
}

